.confirmDialog .p-dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 0 1rem 1.5rem;
}

.p-dialog .p-dialog-footer button {
    margin: 0 !important;
    margin-left: 1% !important;
    margin-right: 1% !important;
}

.p-confirm-dialog .p-dialog-content {
  justify-content: center !important;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  flex-grow: 0 !important;
}

.confirmDialog.p-dialog .p-dialog-header {
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  padding: 20px 27px 10px 20px !important
}

.confirmDialog.p-dialog {
  box-shadow: none !important;
  height: 294px !important;
  width: 411px !important;

  .p-dialog-header .p-dialog-title {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: "Poppins" !important;
    color: #898f9b !important;
  }
}

.confirmDialog.p-dialog .p-dialog-footer {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.confirmDialog.p-dialog .p-dialog-header::after {
  content: "";
  position: absolute;
  height: 1px;
  background: #eef1f0;
  width: 100%;
  top: 20%;
  right: 0% !important;
}

.confirmDialog.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 1.5rem !important;
  height: 1.5rem !important;

  &:focus {
    box-shadow: none !important;
  }

  span {
    font-size: 15px !important;
    color: #898F9B !important;
  }
}

.confirmation-btn {
  position: relative;
  padding: 6px 10px;

  span {
    font-family: "Poppins" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    align-items: center !important;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 37%;
    transform: translate(-50%, -50%);
    width: 110px;
    height: 100%;
    background: #eef1f0;
    border-radius: 5px;
    box-shadow: 0px 12px 15px rgba(51, 51, 85, 0.3);
    z-index: -1;
  }

  &.confirm {
    background-color: #434343 !important;
    border-color: #434343 !important;
    border-radius: 5px !important;
    height: 30px !important;
    width: 120px !important;
  }

  &.reject {
    background-color: var(--bg-white) !important;
    border-color: var(--primary-bg) !important;
    color: var(--primary-bg);
    border-radius: 5px !important;
    height: 30px !important;
    width: 120px !important;

    &:hover {
      background-color: var(--bg-white) !important;
      border-color: var(--primary-bg) !important;
      color: var(--primary-bg);
    }
  }

  .p-button-icon {
    display: none !important;
  }
}

.confirmDialog .p-dialog-content {
  border-bottom: 2px solid #eef1f0;
}

.confirmDialog .p-dialog-content {
  flex-direction: column !important;
  

  .p-confirm-dialog-icon {
    display: none !important;
  }

  .p-confirm-dialog-message {
    span {
      color: var(--primary-bg);
    }
    margin: 25px 9px;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 30px !important;
    font-family: "Poppins" !important;
    color: var(--text-black) !important;
    // width: 19.5rem;
    text-align: center;
  }
}