/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {}


/* Samsung Galaxy Fold ----------- */
@media (min-width: 280px) and (max-width: 320px) {}


/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    
}

/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {

    .content_wrapper.open-bar {
        max-width: calc(100vw - 16%);
    }

    .content_wrapper.expanded-bar {
        max-width: calc(100vw - 67px);
    }

    .p-sidebar-left{
        width: 15rem !important;
    }
}


/* Large screens ----------- */
@media only screen and (min-width : 1824px) {

    .content_wrapper.open-bar {
        max-width: calc(100vw - 12%);
    }

    .sidebar_wrapper.open-bar {
        width: 11.5% !important;

        .p-sidebar-header {
            top: 9.8% !important;
            left: 10.8vw !important;
        }
    }

    .sidebar_wrapper.expanded-bar {

        .p-sidebar-header {
            top: 9.8% !important;
            left: 2.8vw !important;
        }
    }

    .p-sidebar-left{
        width: 16rem !important;
    }
    // .sidebar_wrapper{
    //     width: 11.5% !important;
    // }

    // .content_wrapper{
    //     max-width: 89vw;
    // }


}


@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .signin-div {
        width: 100% !important;
    }

    .image-div {
        display: none;
    }
}
  