// presets start
@import "/src/assets/styles/_variables.scss";
@import "/src/assets/styles/_fonts.scss";

.form-group{
    .control-label{
        color: var(--jet-black);
        font-weight: 500;
        font-size: 14px;
    }
}

.form-button.p-button-outlined.secondary {
    background: transparent !important;
    border-color: var(--primary-bg) !important;
    color: var(--jet-black);

    padding: 6px 10px;
    font-weight: 300 !important;
    border-radius: 9px;
    max-width: 200px;
    width: 150px;

    span {
        font-weight: 400 !important;
        font-family: 'Roboto', sans-serif;
    }

    &:hover {
        background: var(--secondary-button-bg) !important;
        color: var(--jet-black) !important;
    }

    &:focus {
        box-shadow: 0 0 0 2px var(--bg-white), 0 0 0 4px var(--secondary-button-bg), 0 1px 2px 0 black;
    }
}

.p-calendar{
    width: 100% !important;
    // margin-top: 2.8% !important;
}

.p-dropdown-open{
    .p-dropdown-trigger .pi-chevron-down{
        // transition:0.5s;
        // transform: rotate(180deg);
    }
}
