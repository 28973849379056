:root{
    --primary-bg: #96444B;
    --primary-diable-bg: #9aa4ab;
    --active-menu-bg: #F4ECED;
    --active-menu-text-color: #96444B;
    --Inactive-menu-text-color: #4F4F4F;
    --dim-blue: #0E3E45;
    --secondary-bg: #00B23E;
    --menulink-hover-bg: rgb(239, 239, 239);
    --bg-white: #fff;
    --bg-grey: #F2F2F2;
    --bg-dimgrey: #F5F5F5;
    --bg-lightgrey: #BEBEBE;
    --bg-midlightgrey: #585858;
    --bg-midgrey: #777777;
    --bg-mud-grey: rgba(34, 43, 69, 0.5);
    --jet-black: #000000;
    --text-black: #191919;
    --dim-black: #4F4F4F;
    --text-grey: #A6A6A6;
    --text-grey-secondary: #BCBCBC;
    --text-color-grey3: #898F9B;
    --dim-grey: #E9E9E9;
    --grey-tertiary: #E8E8E8;
    --grid-header-text-color: #8A8A8A;
    --sky-blue: #3EA9F5;
    --dim-orange: #DF6C4F;
    --light-yellow: #FBCE50;
    --pearl-green: #4FDF89;
    --primary-button-border: #496174;
    --secondary-button-bg: #E3E3E3;
    --bg-link-blue: #2F80ED;
    --bg-progressbar: #DBDBDB;
    --text-danger: #E84622;
    --delete-red: #F7685B;
    --incorrect-red: #FF7878;
    --success-green: #64B745;
    --button-grey:#434343;


}