@font-face {
    font-family: 'icomoonACV';
    src: url('../fonts/icomoonACV.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="ic-"], [class*=" ic-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoonACV' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .ic-chevron-down:before {
    content: "\e912";
  }
  .ic-chevron-up:before {
    content: "\e913";
  }
  .ic-plus:before {
    content: "\e911";
  }
  .ic-drivers:before {
    content: "\e906";
    color: #4f4f4f;
  }
  .ic-trailers:before {
    content: "\e908";
    color: #4f4f4f;
  }
  .ic-truck:before {
    content: "\e905";
    color: #4f4f4f;
  }
  .ic-customer:before {
    content: "\e91b";
    color: #4f4f4f;
  }
  .ic-book:before {
    content: "\e91a";
  }
  .ic-double-bar:before {
    content: "\e918";
  }
  .ic-marker:before {
    content: "\e919";
  }
  .ic-setting:before {
    content: "\e917";
  }
  .ic-head-phones:before {
    content: "\e903";
    color: #4f4f4f;
  }
  .ic-role:before {
    content: "\e915";
    color: #4f4f4f;
  }
  .ic-location:before {
    content: "\e916";
    color: #4f4f4f;
  }
  .ic-back-arrow:before {
    content: "\e914";
  }
  .ic-arrow-right:before {
    content: "\e90f";
    color: #96444b;
  }
  .ic-arrow-left:before {
    content: "\e910";
    color: #898f9b;
  }
  .ic-aim:before {
    content: "\e90e";
    color: #4f4f4f;
  }
  .ic-filter:before {
    content: "\e90d";
  }
  .ic-chev-right:before {
    content: "\e90a";
  }
  .ic-milestone:before {
    content: "\e909";
    color: #4f4f4f;
  }
  .ic-cancel-circle:before {
    content: "\e90b";
    color: #4f4f4f;
  }
  .ic-calednar:before {
    content: "\e90c";
    color: #4f4f4f;
  }
  .ic-setting-gear:before {
    content: "\e901";
  }
  .ic-pin-location:before {
    content: "\e902";
    color: #4f4f4f;
  }
  .ic-person:before {
    content: "\e904";
    color: #4f4f4f;
  }
  .ic-import:before {
    content: "\e900";
    color: #4f4f4f;
  }
  
  
  