@font-face {
    font-family: 'icomoon';
    src: url('https://i.icomoon.io/public/temp/dc01eb2529/digicom/icomoon.eot?9y2hx4');
    src: url('../fonts/icomoon.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-arrow-topR:before {
  content: "\e930";
}
.i-warning-board:before {
  content: "\e92f";
  color: #ff0009;
}
.i-double-check:before {
  content: "\e92e";
}
.i-check-circle:before {
  content: "\e92d";
}
.i-copy:before {
  content: "\e92c";
}
.i-arrow-refresh:before {
  content: "\e92a";
  color: #fff;
}
.i-ellipse2:before {
  content: "\e92b";
  color: #ff3a41;
}
.i-attachment:before {
  content: "\e929";
}
.i-left:before {
  content: "\e927";
  color: #a6a6a6;
}
.i-right:before {
  content: "\e928";
  color: #a6a6a6;
}
.i-chevfilled-left:before {
  content: "\e925";
}
.i-chevfilled-right:before {
  content: "\e926";
}
.i-reorder:before {
  content: "\e924";
}
.i-history:before {
  content: "\e923";
  color: #364958;
}
.i-chevfilled-down:before {
  content: "\e922";
}
.i-export-circle:before {
  content: "\e921";
  color: #00b23e;
}
.i-trash-bin:before {
  content: "\e920";
  color: #a6a6a6;
}
.i-check:before {
  content: "\e91f";
  color: #00b23e;
}
.i-Edit:before {
  content: "\e91d";
}
.i-Calander:before {
  content: "\e91e";
  color: #bababa;
}
.i-info:before {
  content: "\e91b";
}
.i-setting-pro:before {
  content: "\e91a";
}
.i-Down:before {
  content: "\e919";
  color: #a6a6a6;
}
.i-Up:before {
  content: "\e918";
  color: #a6a6a6;
}
.i-cross-square:before {
  content: "\e917";
  color: #df6c4f;
}
.i-cross-round:before {
  content: "\e916";
  color: #ff3a41;
}
.i-check-round:before {
  content: "\e915";
  color: #00b23e;
}
.i-cancel:before {
  content: "\e914";
}
.i-warn:before {
  content: "\e91c";
  color: #d9dade;
}
.i-ellipsis-v:before {
  content: "\e913";
}
.i-grid-sort:before {
  content: "\e912";
  color: #c1c1c1;
}
.i-page-next:before {
  content: "\e910";
  color: #00b23e;
}
.i-page-prev:before {
  content: "\e911";
  color: #00b23e;
}
.i-frame:before {
  content: "\e90b";
}
.i-filter:before {
  content: "\e90c";
}
.i-download:before {
  content: "\e90d";
}
.i-upload:before {
  content: "\e90e";
}
.i-search:before {
  content: "\e90f";
}
.i-notify-bell:before {
  content: "\e909";
}
.i-sign-out:before {
  content: "\e90a";
}
.i-down-chevron:before {
  content: "\e908";
}
.i-left-arrow:before {
  content: "\e907";
}
.i-shopping-bag:before {
  content: "\e906";
}
.i-floppy:before {
  content: "\e902";
}
.i-users:before {
  content: "\e903";
}
.i-user-settings:before {
  content: "\e904";
}
.i-dashboard:before {
  content: "\e905";
}
.i-eyeclose:before {
  content: "\e900";
  color: #d3d4d5;
}
.i-eyeopen:before {
  content: "\e901";
  color: #d3d4d5;
}
.i-user:before {
  content: "\e971";
}
.i-menu:before {
  content: "\ea7a";
}
